import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { useTranslation } from "react-i18next";
import { FormInputs } from "./data/FormInputs";
import { UserData } from "helpers/ClientHelper";
import { FormMapping } from "./data/FormMapping";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FormInputs as goCuotasInputs } from "./data/FormInputsGoCuotas";
import { FormInputs as mercadoPagoInputs } from "./data/FormInputsMercadoPago";

const AdminPaymentMethodsForm = () => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  let { id } = useParams();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const handleChange = (e) => {
    if (e.target.name === "provider_id") {
      loadContent({}, onSubmit, e.target.value);
    }
  };

  let formAction = t("Create Item");
  let labelPrefix = "";
  let buttonText = t("Store");

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/",
      redirectUrl: "/admin/config/payment_methods",
    }),
    []
  );

  if (id) {
    formAction = t("Edit Item");
    buttonText = t("Update");
  }
  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then((result) => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const loadContent = useCallback(
    (values, onSubmit, selected = "") => {
      let inputs = FormInputs;
      switch (selected) {
        case "3":
          inputs = goCuotasInputs;
          break;
        case "2":
          inputs = mercadoPagoInputs;
          break;

        default:
          break;
      }
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={inputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  submitCallback={onSubmit}
                  errorCallback={handleShow}
                  changeCallback={handleChange}
                  update={id ? true : false}
                  footer={t(
                    "When you resize the screen, the image will adapt and be cropped to maintain the aesthetics of the page."
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [
      buttonText,
      formAction,
      handleShow,
      id,
      labelPrefix,
      target,
      t,
      handleChange,
    ]
  );

  const onSubmit = useCallback(
    (formState, event) => {
      refetchCustomer().then((response) => {
        if (!response || !response.data) {
          handleShow();
          return;
        }
        let newData = response.data.data.data;
        if (!newData) {
          newData = {};
        }
        if (
          !Object.hasOwnProperty.call(newData, "payment_methods") ||
          Array.isArray(newData.payment_methods)
        ) {
          newData["payment_methods"] = {};
        }
        let data = formState;
        switch (formState.provider_id) {
          case "2":
            data.name = "MercadoPago";
            break;
          case "3":
            data.name = "GoCuotas";
            break;

          default:
            data.name = "Manual";
            break;
        }
        newData.payment_methods[formState.provider_id] = data;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then((response) => {
            refetchCustomer().then(() => {
              setGoto(target.redirectUrl);
              return;
            });
          })
          .catch((error) => {
            handleShow();
          });
      });
    },
    [handleShow, refetchCustomer, target.method, target.uri, target.redirectUrl]
  );

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      if (id) {
        let values = customer.data.data.payment_methods[id];
        for (const key in FormMapping) {
          const element = FormMapping[key];
          values[key] = customer.data.data.payment_methods[id][element];
        }
        refetchCustomer();
        loadContent(values, onSubmit, id);
      } else {
        refetchCustomer();
        loadContent({}, onSubmit);
      }
      target.uri = "user/" + customer.data.id;
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    loaded,
    customer,
    id,
    loadContent,
    refetchCustomer,
    target,
    goto,
    navigate,
    onSubmit,
  ]);

  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default AdminPaymentMethodsForm;
