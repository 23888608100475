import Swal from "sweetalert2";
import Joyride from "react-joyride";
import Cart from "components/client/cart/Cart";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "helpers/helper";
import { ClientView } from "views/client/Client";
import { useCallback, useEffect, useState } from "react";
import { CartData, UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { joyrideTranslate } from "helpers/functionsHelper";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PaymentShipment } from "components/client/PaymentShipment";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export const OrderView = ({ customerPath, customer }) => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);
  const { data: cartData, refetch } = CartData(customer.id);
  const { data: currentUser, refetch: refetchUser } = UserData();
  const [goto, setGoto] = useState();
  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const { paymentId } = useParams();
  const [shipmentPrice, setShipmentPrice] = useState();
  const [runOnboarding, setRunOnboarding] = useState(false);

  let steps = [
    {
      target: ".first-order-step",
      content: t(
        "Here you can choose if you want to arrange shipping after making the purchase (contacting the seller) or if you want to choose an address to include shipping."
      ),
      disableBeacon: true,
    },
    {
      target: ".second-order-step",
      content: t("Here you can see the shopping cart before completing it."),
    },
  ];

  if (
    customer &&
    customer.data &&
    customer.data.payment_methods &&
    customer.data.payment_methods.length
  )
    steps.push({
      target: ".fourth-order-step",
      content: t(
        "Here you can choose how to pay (if you choose Manual the seller will contact you to make the payment)."
      ),
    });

  steps.push({
    target: ".third-order-step",
    content: t("With this button you will advance with the purchase process."),
  });

  const handleStartTour = () => {
    setRunOnboarding(true);
  };

  const handleSkip = (e) => {
    if (["skip", "close"].includes(e.action)) {
      setRunOnboarding(false);
      if (currentUser) {
        const target = {
          method: "PUT",
          uri: "user/" + (currentUser ? currentUser.data.id : ""),
        };
        let newData = currentUser.data.data;
        if (!newData) newData = {};
        newData["orderOnboarding"] = 1;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then(() => {
            refetchUser();
          })
          .catch();
      }
    }
  };

  const handleNotification = useCallback(() => {
    const uri = "payment/" + paymentId + "/notification" + location.search;
    const params = new URLSearchParams(location.search);
    if (
      params.get("collection_status") === "approved" ||
      params.get("collection_status") === "in_process"
    ) {
      const title =
        params.get("collection_status") === "approved"
          ? t("Order payed successfully.")
          : t("Order payment is in process.");
      apiRequestUri(uri, "GET")
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: title,
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            setContent(<LoadingSpinner />);
            refetch().then(() => {
              setGoto("/" + customerPath);
            });
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: t("An error has occurred."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then((result) => {
            setContent(<LoadingSpinner />);
            setGoto("/" + customerPath);
          });
        });
    } else {
      apiRequestUri(uri, "GET")
        .then((response) => {
          const title =
            response.status === "paid"
              ? t("Order payed successfully.")
              : t("Order payment is in process.");
          Swal.fire({
            icon: "success",
            title: title,
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            setContent(<LoadingSpinner />);
            refetch().then(() => {
              setGoto("/" + customerPath);
            });
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: t("An error has occurred."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then((result) => {
            setContent(<LoadingSpinner />);
            setGoto("/" + customerPath);
          });
        });
    }
  }, [closeTime, customerPath, location.search, paymentId, refetch, t]);

  const loadContent = useCallback(() => {
    const successCallback = (response) => {
      if (response?.payment?.data) {
        if (response.payment.data.checkout_url)
          window.location.href = response.payment.data.checkout_url;
        else if (response?.payment?.payment_provider_id === 1) {
          Swal.fire({
            icon: "success",
            title: t("Order created successfully."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          });
          refetch().then(() => {
            setGoto("/" + customerPath);
          });
        } else errorCallback(response);
      } else {
        Swal.fire({
          icon: "success",
          title: t("Order created successfully."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        refetch().then(() => {
          setGoto("/" + customerPath);
        });
      }
    };

    const errorCallback = (error) => {
      let message = null;
      message = error?.response?.data;
      console.log(error);
      Swal.fire({
        icon: "error",
        title: message ? message.message : t("An error has occurred."),
        showConfirmButton: message ? true : false,
        timer: message ? null : closeTime,
        timerProgressBar: true,
      }).then((result) => {
        setContent(<></>);
        setLoaded(false);
      });
    };

    const shipmentPriceCallback = (price) => {
      setShipmentPrice(price);
      setLoaded(false);
    };

    setContent(
      <Row className="mx-md-5">
        <Col md={4} xs={12}>
          <PaymentShipment
            className="my-md-5 my-2"
            customerPath={customerPath}
            customer={customer}
            addresses={currentUser?.data?.addresses}
            successCallback={successCallback}
            errorCallback={errorCallback}
            shipmentPriceCallback={shipmentPriceCallback}
          />
        </Col>
        <Col>
          <Card className="my-md-5">
            <Card.Body>
              <Card.Title>{t("Your shopping cart")}</Card.Title>
              <hr />
              <Cart
                toAddPrice={shipmentPrice}
                className="m-0 p-0 w-100 second-order-step"
                cart={cartData?.data}
                unitPrice={unitPrice}
                customer={customer}
                customerPath={customerPath}
                showButtons={false}
                handleStartTour={handleStartTour}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
    if (!currentUser?.data?.data?.orderOnboarding)
      refetchUser().then((response) => {
        if (!response?.data?.data?.data?.orderOnboarding)
          setRunOnboarding(true);
      });
  }, [
    shipmentPrice,
    cartData,
    customer,
    customerPath,
    unitPrice,
    currentUser,
    refetch,
    closeTime,
    t,
    refetchUser,
  ]);

  useEffect(() => {
    if (!loaded) {
      if (lastUrlSegment === "notification") handleNotification();
      else if (currentUser && currentUser.data) {
        if (
          cartData &&
          cartData.data &&
          cartData.data.cart &&
          !cartData.data.cart.length
        ) {
          setGoto("/" + customerPath);
        } else loadContent();
      } else if (currentUser && !currentUser.data) {
        setGoto("/" + customerPath);
      } else loadContent();
      setLoaded(true);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
    if (cartData?.data) {
      loadContent();
    }
  }, [
    cartData,
    currentUser,
    loadContent,
    loaded,
    goto,
    navigate,
    customerPath,
    handleNotification,
    lastUrlSegment,
  ]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Joyride
        scrollDuration={1000}
        hideCloseButton
        locale={joyrideTranslate(t)}
        continuous
        run={runOnboarding}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        callback={handleSkip}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Container fluid className="text-center">
        {content}
      </Container>
    </ClientView>
  );
};
