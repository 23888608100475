export const FormInputs = [
  {
    type: "select",
    name: "provider_id",
    value: "",
    options: [
      {
        text: "Manual",
        value: "1",
      },
      {
        text: "Mercado Pago",
        value: "2",
      },
      {
        text: "Go Cuotas",
        value: "3",
      },
    ],
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
];
