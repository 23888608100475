import { ProductItem } from "./ProductItem";
import "bootstrap/dist/css/bootstrap.min.css";

export const CatalogeList = ({ products, customer, customerPath }) => {
  return (
    <div className="row">
      {products?.map((product, index) => (
        <div className="col-md-4 d-flex align-items-stretch" key={index}>
          <ProductItem
            product={product}
            customer={customer}
            customerPath={customerPath}
          />
        </div>
      ))}
    </div>
  );
};
