export const TableMapping = {
  // column: data
  ID: {
    property: "id",
    transform: (data, row) => {
      if (row.provider_id === "Manual") return "1";
      if (row.provider_id === "Mercado Pago") return "2";
      if (row.provider_id === "Go Cuotas") return "3";
      return row.provider_id;
    },
    sortable: true,
  },
  Provider: {
    property: "provider_id",
    transform: (data) => {
      if (data === "1") return "Manual";
      if (data === "2") return "Mercado Pago";
      if (data === "3") return "Go Cuotas";
      return data;
    },
    sortable: true,
  },
};
