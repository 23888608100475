import Swal from "sweetalert2";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";
import { useForm } from "../hooks/useForm";
import { useTranslation } from "react-i18next";
import { UserData } from "../helpers/ClientHelper";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  apiPasswordAuth,
  apiRequestAccess,
  getCookie,
  setCookie,
} from "../helpers/helper";
import { useSearchParams } from "react-router-dom";

const LoginView = ({ customerPath }) => {
  const { t } = useTranslation();
  const { data: user, refetch } = UserData();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const initialForm = {
    username: "",
    password: "",
  };
  const { formState, username, password, onInputChange } = useForm(initialForm);
  const defaultButton = useMemo(
    () => <Button as="input" type="submit" value={t("Login")} />,
    [t]
  );
  const [currentButton, setButton] = useState(defaultButton);
  const [title, setTitle] = useState(process.env.REACT_APP_NAME);
  const [googleBtnContent, setGoogleBtnContent] = useState(
    t("Sign In with Google")
  );
  const [searchParams] = useSearchParams();
  const googleCode = searchParams.get("code");
  const location = useLocation();

  useEffect(() => {
    if (customerPath) {
      setTitle(customerPath.charAt(0).toUpperCase() + customerPath.slice(1));
    }
    if (user && user.data) {
      const googleToken = getCookie("google_token");
      if (user.data.role_name !== "client") {
        navigate("/admin");
      } else if (customerPath) {
        navigate("/" + customerPath);
      } else if (googleToken) {
        const fromUrl = getCookie("redirect_url");
        refetch().then(() => (window.location = fromUrl));
      } else {
        Swal.fire({
          icon: "error",
          title: t("User is Client."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      }
    }
    if (googleCode) {
      const url =
        process.env.REACT_APP_API_URL +
        "/api/auth/google/callback?" +
        searchParams.toString();
      apiRequestAccess(url, "GET", null, false, {}, false)
        .then((response) => {
          setCookie("access_token", response.data.access_token).then(() => {
            setCookie("google_token", response.data.google_token).then(() => {
              const fromUrl = getCookie("redirect_url");
              refetch().then(() => {
                window.location = fromUrl;
              });
            });
          });
        })
        .catch((error) => {
          navigate("/login");
        });
    }
  }, [
    user,
    customerPath,
    navigate,
    closeTime,
    defaultButton,
    t,
    googleCode,
    refetch,
    searchParams,
  ]);

  const onSubmit = (event) => {
    event.preventDefault();
    var grant_type = "password";
    var data = {
      password: formState.password,
      username: formState.username,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: grant_type,
    };
    setButton(<LoadingSpinner />);
    apiPasswordAuth(data)
      .then(() => {
        refetch();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("Invalid password or email."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      });
  };

  const googleLogin = () => {
    const hostUrl = window.location.href.replace(location.pathname, "");
    const url = process.env.REACT_APP_API_URL + "/api/auth/google/redirect";
    if (customerPath) setCookie("redirect_url", `${hostUrl}/${customerPath}`);
    else setCookie("redirect_url", `${hostUrl}/admin/product`);
    setGoogleBtnContent(<LoadingSpinner size="sm" />);
    apiRequestAccess(url, "GET", null, false, {}, false)
      .then((response) => {
        window.location = response.data.redirect_url;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      {googleCode ? (
        <Container fluid className="mt-5 text-center">
          <LoadingSpinner />
        </Container>
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="card">
                <div className="card-body m-3">
                  <Row>
                    <Col xs={1}>
                      <Link
                        to={customerPath ? "/" + customerPath : "/"}
                        className="link-secondary h4"
                      >
                        <FontAwesomeIcon
                          icon={faHome}
                          className="mt-3 ms-3 me-2"
                        />
                      </Link>
                    </Col>
                    <Col>
                      <h4 className="text-center mx-3 mt-3">
                        {t("Welcome to")}
                      </h4>
                    </Col>
                    <Col xs={1}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4 className="text-center mb-md-4 mb-2">{title}!</h4>
                    </Col>
                  </Row>
                  <form onSubmit={onSubmit}>
                    <div className="m-md-3 mb-md-4 mb-2">
                      <input
                        type="email"
                        className="form-control"
                        name="username"
                        placeholder={t("Email")}
                        value={username}
                        onChange={onInputChange}
                        required
                      />
                    </div>
                    <div className="m-md-3 mb-md-4 mb-2">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder={t("Password")}
                        value={password}
                        onChange={onInputChange}
                        required
                      />
                    </div>
                    <div className="text-center mx-3">{currentButton}</div>
                  </form>
                  <hr />
                  <div className="text-center">
                    <Button
                      variant="light"
                      onClick={googleLogin}
                      title="Log in with Google"
                      className="border"
                      data-qa="google-login-button"
                      style={{ width: 250 }}
                    >
                      <Row>
                        <Col xs={3} className="text-end">
                          <img src="/images/google.svg" alt="Google" />
                        </Col>
                        <Col className="text-center">
                          <div className="p d-inline">{googleBtnContent}</div>
                        </Col>
                      </Row>
                    </Button>
                  </div>
                  <p className="small fw-bold mt-0 pt-1 mb-0 ms-md-3">
                    <Link
                      to={(customerPath ? "/" + customerPath : "") + "/forgot"}
                      className="link-danger"
                    >
                      {t("Forgot Password?")}
                    </Link>
                    <br />
                    {t("Don't have an account?")}{" "}
                    <Link
                      to={
                        (customerPath ? "/" + customerPath : "") + "/register"
                      }
                      className="link-danger"
                    >
                      {t("Register")}
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default LoginView;
