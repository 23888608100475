import { appLogout } from "helpers/helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserData } from "helpers/ClientHelper";

export const AdminRedirection = () => {
  const { data: currentUser, remove, refetch } = UserData();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      if (currentUser && currentUser.data && currentUser.data.name) {
        if (currentUser.data.role_name === "admin") {
          navigate("/admin/user");
        } else if (currentUser.data.role_name === "customer") {
          navigate("/admin/product");
        } else {
          navigate("/login");
        }
        setLoaded(true);
      } else {
        refetch().then((response) => {
          if (!response.data) {
            remove();
            appLogout();
            navigate("/login");
          }
        });
      }
    }
  }, [loaded, currentUser, navigate, remove, refetch]);

  return;
};
