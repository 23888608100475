export const FormInputs = [
  {
    type: "text",
    name: "title",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
  {
    type: "textarea",
    name: "description",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
  {
    type: "text",
    name: "keywords",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
  {
    type: "image",
    name: "image",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
];
